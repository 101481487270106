

body {
  background-color: #FCFCF0;
  color: 916C23;
}

* { 
  box-sizing: border-box;
}

footer {
  background-color: #916C23;
  color: #FCFCF0;
  font-style: normal;
  font-size: 12px;
}

/* column and row used for page footer */

/* create 3 equal columns that float next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  text-align: left;
}

/* clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout makes 3 columns stack on top of each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

/* end of footer column and row styles */

/*  center the Collection component on the screen */
.collectiondiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepagediv {
  background: url('./doorwaypageimage.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #916c23;
  width: 100%;
  height: 600px;
}

.homepagedivmobile {
  background: url('./doorwaypageimagemobile.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #916c23;
  width: 100%;
  height: 600px;
}

.aboutcontentdiv {
  color: #916c23;
  text-align: left;
  margin-left: 220px;
  margin-right: 20px;
}

.aboutcontentdivmobile {
  color: #916c23;
  text-align: left;
  margin-left: 70px;
  margin-right: 20px;
}

.aboutpagediv {
  background: url('./ascensionpageimage.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #916c23;
  width: 100%;
  height: 600px;
}

.aboutpagedivmobile {
  background: url('./ascensionpageimagemobile.png');
  background-repeat: no-repeat;
  background-position: center;
  color: #916c23;
  width: 100%;
  height: 600px;
}

.copyrightdiv {
  text-align: left;
  margin-left: 20px;
}

.centereddiv {
  display: flex;
  text-align: center;
  word-wrap: normal;
  margin-left: 20%;
  margin-right: 20%;
}

.centered {
  text-align: center;
}

.link-image {
  cursor: pointer !important;
}

/* display profile view/edit card in center
.codediv {
  display: flex;
  position: relative;
  height: 200px;
  width: 300px;
}


/* react-burger-menu specific styles */
* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

.burger-margins {
  margin-right: 80px;
  margin-bottom: 0px;
}

/* end of react-burger-menu styles */


