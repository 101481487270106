
.modal {
  position: fixed;
  z-index: 1; /* sit on top */
  padding-top: 100px;  /* location of the box */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;  /* full width */
  height: 100%; /* full height */
  overflow: auto; /* enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  width: 350px;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.2s;
  animation-name: animatetop;
  animation-duration: 0.2s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

.modal-title {
  margin: 0;
}

.modal-header {
  color: #ffffff;
  background: #373e98;
}

.modal-body {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly;  /* items have equal space around them */
  padding-top: 5px;
  padding-bottom: 8px;
}

.modal-message {
  text-align: left;
  color: #ff0000;
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 8px;
}

.modal-date-input {
  display: flex;  /* layout items in a horizontal row */
  align-items: stretch; /* vertical alignment; baseline | center | stretch */
}

